<template>
    <main class="main">
        <template v-if="client">
            <template v-if="isStage('client')">
                <div class="main__head">
                    <h1 class="h1">
                        Редактирование клиента
                    </h1>
                </div>
                <client
                    :values="client"
                    @prev="prevClient"
                    @next="nextClient"
                />
            </template>
            <template v-if="isStage('verify')">
                <div class="main__head">
                    <h1 class="h1">
                        Верификация клиента
                    </h1>
                </div>
                <verify
                    v-if="isStage('verify')"
                    :values="client"
                    header=""
                    @prev="prevVerify"
                    @next="nextVerify"
                />
            </template>
        </template>

        <modal
            :is-shown="!!state"
            @hide="next"
        >
            <template v-if="state === 'updated_verified'">
                <h3
                    slot="header"
                    class="modal__header h2"
                >
                    Клиент обновлен
                </h3>
                <div
                    slot="content"
                    class="modal__content text"
                >
                    <p>Информация о клиенте успешно обновлена.</p>
                    <p>Нажмите OK для перехода к главному меню.</p>
                </div>
                <div
                    slot="footer"
                    class="modal__panel"
                >
                    <button
                        class="modal__submit button button--green"
                        @click="next"
                    >
                        OK
                    </button>
                </div>
            </template>
            <template v-else-if="state === 'updated_unverified'">
                <h3
                    slot="header"
                    class="modal__header h2"
                >
                    Клиент обновлен
                </h3>
                <div
                    slot="content"
                    class="modal__content text"
                >
                    <p>Информация о клиенте успешно обновлена.</p>
                    <p>Вы можете верифицировать его телефон или пропустить этот шаг.</p>
                    <p>Нажмите "Пропустить" для перехода к главному меню.</p>
                    <p>Нажмите "Верифицировать" для перехода к верификации.</p>
                </div>
                <div
                    slot="footer"
                    class="modal__panel"
                >
                    <button
                        class="modal__prev button button--yellow"
                        @click="next"
                    >
                        Пропустить
                    </button>
                    <button
                        class="modal__next button button--green"
                        @click="state = undefined; goTo('verify');"
                    >
                        Верифицировать
                    </button>
                </div>
            </template>
            <template v-else-if="state === 'verified'">
                <h3
                    slot="header"
                    class="modal__header h2"
                >
                    Клиент верифицирован
                </h3>
                <div
                    slot="content"
                    class="modal__content text"
                >
                    <p>Клиент успешно верифицирован.</p>
                    <p>Нажмите OK для перехода к главному меню.</p>
                </div>
                <div
                    slot="footer"
                    class="modal__panel"
                >
                    <button
                        class="modal__submit button button--green"
                        @click="next"
                    >
                        OK
                    </button>
                </div>
            </template>
            <template v-else-if="state === 'unverified'">
                <h3
                    slot="header"
                    class="modal__header h2"
                >
                    Клиент не верифицирован
                </h3>
                <div
                    slot="content"
                    class="modal__content text"
                >
                    <p>Клиент не был верифицирован.</p>
                    <p>Вы можете провести верификацию позже.</p>
                    <p>Нажмите OK для перехода к главному меню.</p>
                </div>
                <div
                    slot="footer"
                    class="modal__panel"
                >
                    <button
                        class="modal__submit button button--green"
                        @click="next"
                    >
                        OK
                    </button>
                </div>
            </template>
        </modal>
    </main>
</template>

<script>
    import { clients } from '@/services';
    import client from '@/views/operations/client/main.vue';
    import verify from '@/views/operations/verify/main.vue';
    import modal from '@/components/modal.vue';

    export default {
        components: {
            client,
            verify,
            modal
        },
        data() {
            return {
                stage: 'client',
                id: undefined,
                client: undefined,
                state: undefined
            };
        },
        created() {
            this.id = this.$router.currentRoute.query.client_id;
            if (this.id) {
                let client = this.$store.state.client;
                if (client && client.id === this.id) {
                    this.client = client;
                } else {
                    delete this.$store.state.client;

                    this.$store.dispatch('showLoader', { label: 'Загрузка клиента' });

                    clients.getClient(this.id).then((item) => {
                        this.client = item;
                    }).catch((error) => {
                        const err = new Error('Не удалось загрузить клиента');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }).finally(() => {
                        this.$store.dispatch('hideLoader');
                    });
                }
            } else {
                const err = new Error('Параметр client_id не передан. Перейдите на главную страницу и попробуйте заново');
                this.$store.dispatch('showError', { err, vm: this });
            }
        },
        methods: {
            isStage(stage) {
                return this.stage === stage;
            },
            goTo(stage) {
                this.stage = stage;
            },
            prev() {
                this.$router.go(-1);
            },
            next() {
                this.$router.push({ name: 'index' });
            },
            prevClient(client) {
                this.prev();
            },
            nextClient(client) {
                this.$store.dispatch('showLoader', { label: 'Обновление клиента' });

                clients.updateClient(this.id, client).then((data) => {
                    delete this.$store.state.client;
                    return clients.getClient(this.id);
                }).then((item) => {
                    this.client = item;
                    this.state = 'updated_unverified';

                    if (this.client.state.is_verified) {
                        this.state = 'updated_verified';
                    }
                }).catch((error) => {
                    const err = new Error('Не удалось обновить клиента');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            prevVerify(isVerified) {
                this.client.state.is_verified = isVerified;
                this.state = isVerified ? 'verified' : 'unverified';
            },
            nextVerify(isVerified) {
                this.client.state.is_verified = isVerified;
                this.state = isVerified ? 'verified' : 'unverified';
            }
        }
    };
</script>
